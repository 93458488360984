<template>
  <div></div>
  <!-- <div class="banner" v-show="userAgent === 'others'">
    <canvas id="qr"></canvas>
    <div>https://octane.webmarks.info</div>
    <div>
      <v-icon color="#CCC">mdi-microsoft-windows</v-icon>
      <v-icon color="#CCC">mdi-apple</v-icon>
      <v-icon color="#CCC">mdi-android</v-icon>
    </div>
  </div> -->
</template>

<script>
  import QRCode from 'qrcode'

  export default {
    data() {
      return {}
    },
    mounted() {
      // QR
      setTimeout(() => {
        QRCode.toCanvas(document.getElementById('qr'), 'https://octane.webmarks.info', {
          color: {dark: '#EEEEEE', light: '#363636'},
          scale: 5,
          margin: 0,
        })
      }, 0)
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .banner {
    text-align: center;
    color: #ccc;
  }
  .v-icon {
    margin: 5px;
  }
</style>
