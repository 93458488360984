<template>
  <fragment>
    <v-container>

      <div class="d-flex flex-wrap justify-start">
        <v-btn-toggle
          v-model="scoreType"
          color="primary"
          mandatory
        >
          <v-btn
           value="single"
          >Single</v-btn>
          <v-btn
           value="multi"
          >Multi</v-btn>
        </v-btn-toggle>
        <v-btn-toggle
          v-model="deviceType"
          color="primary"
          mandatory
        >
          <v-btn value="all">
            <v-icon :color="deviceType === 'all' ? 'primary' : ''">mdi-cellphone-link</v-icon>
            <span v-if="bodyWidth > 700">&nbsp;All</span>
          </v-btn>
          <v-btn value="desktop">
            <v-icon :color="deviceType === 'desktop' ? 'primary' : ''">mdi-desktop-mac</v-icon>
            <span v-if="bodyWidth > 700">&nbsp;Desktop</span>
          </v-btn>
          <v-btn value="laptop">
            <v-icon :color="deviceType === 'laptop' ? 'primary' : ''">mdi-laptop</v-icon>
            <span v-if="bodyWidth > 700">&nbsp;Laptop</span>
          </v-btn>
          <v-btn value="mobile">
            <v-icon :color="deviceType === 'mobile' ? 'primary' : ''">mdi-cellphone</v-icon>
            <span v-if="bodyWidth > 700">&nbsp;Mobile</span>
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-card flat>
        <v-card-title v-if="scoreType === 'single'">{{ $t('singleScore') }} (Octane Score)</v-card-title>
        <v-card-title v-else>{{ $t('multiScore') }}</v-card-title>
        <GChart
          type="BarChart"
          :data="data"
          :options="options"
        ></GChart>
      </v-card>
    </v-container>

  </fragment>
</template>

<script>
  import { GChart } from 'vue-google-charts'
  
  export default {
    data() {
      return {
        observer: null,
        scoreType: 'single',
        deviceType: 'all',
        head : ['Device', 'Score', {role: 'annotation'}, {role: 'style'}, {role: 'type'}, {role: 'score'}],
      }
    },
    computed: {
      options() {
        return _.assign(this.INIT.CHART_OPTIONS, {
          height: this.examples.length * 60
        })
      },
      examples() {
        let examples = []
        _.forEach(this.rankings, ranking => {
          let name = ranking.cpu ? _.capitalize(ranking.type) + ' (' + ranking.cpu + ')' : ranking.name
          examples.push([name, ranking.scoreSingle, ranking.scoreSingle, '', ranking.type, 'single'])
          examples.push([name, ranking.scoreMulti, ranking.scoreMulti, '', ranking.type, 'multi'])
        })
        examples = this.deviceType === 'all' ? examples : _.filter(examples, ['4', this.deviceType])
        return _.filter(examples, ['5', this.scoreType])
      },
      yours() {
        const result = this.result['score' + _.capitalize(this.scoreType)]
        return result ? ['Yours', result, result, 'color: #994520', this.result.type, this.scoreType] : null
      },
      data() {
        const data = this.yours ? _.concat(this.examples, [this.yours]) : this.examples 
        return _.concat([this.head], _.reverse(_.sortBy(data, ['1'])))
      },
    },
    watch: {
      tabId() {
        // グラフが伸びない不具合対策
        if (this.tabId === 'tab1') {
          window.dispatchEvent(new Event('resize'))
        }
      },
    },
    mounted() {
      this.deviceType = this.device.type || 'all'
      // 数値位置調整
      this.adjustText()
    },
    methods: {
      // 数値位置調整
      adjustText() {
        if (! this.observer) {
          this.observer = new MutationObserver(() => {
            const container = document.querySelector('#tab1 .container')
            if (container) {
              document.querySelectorAll('svg g g g g text').forEach((element) => {
                element.setAttribute('x', container.clientWidth * 0.93)
              })
            }
          })
          this.observer.observe(document.querySelector('#tab1 .v-card'), {
            childList: true,
            subtree: true
          })
        }
      }
    },
    components: { GChart },
    i18n: {
      messages: {
        ja: {
          singleScore: 'シングルコア スコア',
          multiScore: 'マルチコア スコア',
        },
        en: {
          singleScore: 'Single Core Score',
          multiScore: 'Multi Core Score',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-btn-toggle {
    margin-right: 2rem;
    margin-bottom: .5rem;
  }
  .v-card {
    .v-card__title {
      padding: 8px 8px 16px;
    }
    &.v-card--disabled * {
      opacity: .3;
    }
  }
  .v-dialog {
    .v-card {
      padding: 30px 0;
    }
    .v-card__title {
      justify-content: center;
      font-weight: normal;
    }
  }
</style>

