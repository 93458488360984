<template>
  <div></div>
</template>

<script>
  import { Device } from '@capacitor/device'
  import CryptoJS from 'crypto-js'

  export default {
    data() {
      return {}
    },
    async mounted() {
      const device = await this.getDevice()
      this.device = await this.addAdditionalInfo(device)
      // console.log(JSON.stringify(this.device))
    },
    methods: {
      // 端末情報取得
      async getDevice() {
        const device = await Device.getInfo()
        device.cpu = null
        device.type = (this.userAgent === 'android' || this.userAgent === 'ios') ? 'mobile' : null
        device.cpuCores = null
        device.cpuThreads = navigator.hardwareConcurrency || null
        device.memory = navigator.deviceMemory || null
        device.operatingSystem = _.includes(navigator.userAgent, 'CrOS') ? 'chrome' : device.operatingSystem
        device.name = null
        device.manufacturer = (device.operatingSystem === 'windows' && _.includes(device.manufacturer, 'Google')) ? null : device.manufacturer
        device.model = (device.operatingSystem === 'windows' && _.includes(device.model, 'Windows NT')) ? null : device.model
        _.forEach(['isVirtual', 'diskFree', 'diskTotal', 'memUsed', 'webViewVersion'], key => {
          delete device[key]
        })
        return device
      },
      // 追加端末情報
      async addAdditionalInfo(device) {
        let deviceInfo = null
        if (this.userAgent === 'android') {
          // android
          const devices = await this.getDevices()
          deviceInfo = _.find(devices, info => { return _.includes(info.Model, device.model) || _.includes(info.Device, device.model) })
          device.name = _.get(deviceInfo, 'Marketing Name') || null
          device.manufacturer = _.get(deviceInfo, 'Retail Branding') || device.manufacturer
        } else if (this.weiQuery) {
          // windows from WEI Viewer
          deviceInfo = this.getQueryInfo(this.weiQuery)
          if (deviceInfo.cpuThreads === device.cpuThreads) {
            device = _.assign(device, deviceInfo)
          }
          delete device.isMobile
        }
        return device
      },
      getQueryInfo(query) {
        const bytes = CryptoJS.AES.decrypt(query, 'gYJV8B7gAQEz4mizfzA8')
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      },
      // get devices
      async getDevices() {
        const res = await axios.get('https://cors.aroundit.net/index.php', {
          params: {
            url: 'https://storage.googleapis.com/play_public/supported_devices.csv',
            cache: true,
          }
        })
        return res.data ? _.drop(this.csv2json(res.data), 4) : []
      },
      // csv to json
      csv2json(csv) {
        const lines = csv.split(/\r\n|\n/)
        const result = []
        const headers = lines[0].split(',')
        for(let i = 1; i < lines.length; i++) {
          const obj = {}
          const currentline = lines[i].split(',')
          for(let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j]
          }
          result.push(obj)
        }
        return result
      },
    },
  }
</script>
