<template>
  <fragment>
    
    <TheAppbar title="Octane 2.0 plus" :tabItems="tabItems">
      <!-- <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn> -->
    </TheAppbar>
    
		<!-- コンテンツ -->
    <v-main>
      <v-tabs-items
        v-model="tabId"
      >
        <v-tab-item value="tab0">
          <Tab0 />
        </v-tab-item>
        <v-tab-item value="tab1">
          <Tab1 />
        </v-tab-item>
      </v-tabs-items>
    </v-main>

    <!-- シェアボタン -->
    <Share />

    <!-- QRコード -->
    <!-- <canvas id="qr" v-show="platform === 'web' && bodyWidth > 1000"></canvas> -->
    
  </fragment>
</template>

<script>
  import TheAppbar from '../commons/components/TheAppbar.vue'
  import Tab0 from './Tab0.vue'
  import Tab1 from './Tab1.vue'
  import Share from './Share.vue'
  import SUITES from '../jsons/suites.json'
  // import QRCode from 'qrcode'

  export default {
    data() {
      return {
        tabItems: [{
          id: 'tab0',
          title: {ja: 'run', en: 'run'},
          icon: 'mdi-speedometer',
					scrollTop: 0,
        }, {
          id: 'tab1',
          title: {ja: 'graph', en: 'graph'},
          icon: 'mdi-format-list-numbered',
					scrollTop: 0,
        }],
      }
    },
    computed: {
    },
    async mounted() {
      // WEI Viewer query
      if (_.has(this.urlParams, 'q')) {
        this.weiQuery = decodeURIComponent(this.urlParams.q)
        window.location.href = location.protocol + '//' + location.host + location.pathname
      }
      // QR
      // QRCode.toCanvas(document.getElementById('qr'), 'https://octane.webmarks.info', {
      //   color: {dark: '#BBBBBB', light: '#272727'},
      //   errorCorrectionLevel: 'low',
      //   scale: 2.7,
      //   margin: 0,
      // })
      // prepare suites
      if (_.isEmpty(this.suites)) {
        this.suites = _.map(SUITES, suite => {
          return _.assign(suite, {score: null, scores: [], progress: null})
        })
      }
    },
    watch: {
			// スクロール量保存
      scrollTop(scrollTop) {
				const tab = _.find(this.tabItems, ['id', this.tabId])
				tab.scrollTop = scrollTop
      },
			// スクロール調整（タブ切り替え終了後）
      tabId(tabId) {
				setTimeout(() => {
					const tab = _.find(this.tabItems, ['id', tabId])
					window.scrollTo(0, tab.scrollTop)
				}, 0)
      }
    },
    methods: {
    },
    i18n: {
      messages: {
        ja: {
        },
        en: {
        }
      }
    },
    components: { TheAppbar, Tab0, Tab1, Share },

  }
</script>

<style>
</style>

<style lang="scss" scoped>
  #tab0 {
    background-color: #1a1515;
  }
  .v-window-item {
    min-height: calc(100vh - 98px); // tabのswipe対応
  }
  #qr {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 9999;
  }
</style>
