import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/modules/_i18n'
import _mixin from '../commons/modules//_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'Octane 2.0 plus',
      appTitle: 'Octane 2.0 plus | オンラインベンチマークのマルチコア対応版',
      appDesc: 'Googel Octane 2.0 ブラウザベンチマークのマルチコア対応版',
    }),
    en: Object.assign(_i18n.en, {
      appName: 'Octane 2.0 plus',
      appTitle: 'Octane 2.0 plus | Online Benchmark for Multi Core CPU',
      appDesc: 'Google Octane 2.0 Browser Benchmark + Multi Core CPU support',
    }),
  }
})
