<template>
  <v-card
    class="results"
    color="#B8B7B6"
    :width="suiteWidth[$vuetify.breakpoint.name]"
    :class="{ visible: suite.score }"
  >
    <v-card-title>
      <a :href="suite.url" target="_brank">{{ suite.label }}</a>
      <span>{{ suite.score ? suite.score.toLocaleString() : null }}</span>
    </v-card-title>
    <v-card-text>
      <span>&nbsp;</span>
      <span
        v-for="(score, index) in suite.scores"
        :key="index"
      ><span v-if="index > 0">&nbsp;/&nbsp;</span>{{ score ? score.toLocaleString() : null }}</span>
    </v-card-text>
    <v-card-actions>{{ suite.desc }}</v-card-actions>
    <div
      class="progress"
      :style="{width: suite.progress === null ? '100%' : (100 - suite.progress) + '%'}"
    ></div>
  </v-card>
</template>

<script>
  export default {
    props: ['suite'],
    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    margin-bottom: .1rem;
    position: relative;
    width: 50%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    &.visible {
      margin-bottom: 1rem;
      opacity: 1;
      height: auto;
    }
  }
  .v-card__title {
    color: #5e3534;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 0;
    a {
      color: #994520;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .v-card__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: #5e3534 !important;
    padding-bottom: 0;
    overflow: hidden;
    height: 1.4rem;
  }
  .v-card__actions {
    padding: 0 16px 8px;
    color: #1a1515;
  }
  .progress {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.2);
    height: 100%;
    transition: width 1s;
  }
</style>
