// global
import 'lodash'
import dayjs from 'dayjs'
import axios from 'axios'
import cfs from './commons/modules/firestore'
window.dayjs = dayjs
window.axios = axios
window.cfs = cfs

// import
import Vue from 'vue'
import App from './App.vue'
import router from './modules/router'
import store from './modules/store'
import vuetify from './commons/modules/vuetify'
import i18n from './modules/i18n'
import './modules/mixin'
// import './registerServiceWorker'

// plugins

// fragment（enable multi root component）
// vue-fragment@1.5.1
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {},
  async mounted() {
    // admob
    // this.admobShow()
  },
  watch: {}
}).$mount('#app')

Vue.config.productionTip = false
