<template>
  <v-snackbar
    v-model="snackbar"
    timeout="10000"
    v-if="isDev"
  >
    Regenerate Ranking
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="generate"
      >Run</v-btn>
    </template>
  </v-snackbar>
</template>

<script>

  export default {
    data() {
      return {
        snackbar: true,
      }
    },
    async mounted() {},
    methods: {
      // ランキング再構築
      async generate() {
        const results = await cfs.getAll('results')
        const namedResults = _.filter(results, result => {
          return result.name && ! result.cpu
        })
        const cpus = _.uniq(_.compact(_.map(results, 'cpu')))
        const names = _.uniq(_.compact(_.map(namedResults, 'name')))
        const medianResults = []
        _.forEach(cpus, cpu => {
          medianResults.push(_.assign(this.getMedianResult(_.filter(results, ['cpu', cpu]))))
        })
        _.forEach(names, name => {
          medianResults.push(this.getMedianResult(_.filter(results, ['name', name])))
        })
        for (const medianResult of medianResults) {
          await cfs.set('rankings', medianResult, medianResult.id)
        }
        console.log(medianResults)
      },
    },
  }
</script>
