<template>
  <v-snackbar
    v-model="snackbar"
    timeout="60000"
    color="black"
  >
    {{ $t('askAdd') }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="primary"
        text
        x-large
        v-bind="attrs"
        @click="resister"
      >{{ $t('add') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { serverTimestamp } from "firebase/firestore"

  export default {
    data() {
      return {
        posted: false,
        snackbar: false,
      }
    },
    computed: {},
    async mounted() {
      // ranking 取得
      // 要変更（たぶんAuthが間に合ってないせいでerror）
      this.rankings = await this.getRankings()
    },
    watch: {
      async result() {
        if (this.result.scoreMulti && ! this.posted) {
          this.snackbar = true
        }
      }
    },
    methods: {
      // ランキング取得
      async getRankings() {
        const rankings = await cfs.getDoc('caches', 'ranking')
        this.updateRanking(rankings.updatedAt)
        return rankings ? JSON.parse(rankings.data) : []
      },
      // 登録
      async resister() {
        this.snackbar = false
        await this.setResult()
        await this.setRanking()
        this.clear4desktop()
      },
      // results
      async setResult() {
        const data = _.assign({}, this.device, this.result)
        data.updatedAt = serverTimestamp()
        if (! this.docId) {
          // new
          data.createdAt = serverTimestamp()
          const res = await cfs.set('results', data)
          this.docId = res.id || this.docId
        } else {
          // update
          await cfs.set('results', data, this.docId)
        }
        this.posted = true
      },
      // 登録
      async setRanking() {
        const results = await cfs.getByQuery('results', this.where)
        if (results.length) {
          const medianResult = this.getMedianResult(results)
          await cfs.set('rankings', medianResult, medianResult.id)
        }
      },
      // clear query & id for WEI Viewer's
      clear4desktop() {
        if (this.device.type === 'desktop') {
          this.weiQuery = null
          this.docId = null
        }
      },
      // ランキングCache更新
      async updateRanking(updatedAt) {
        if (updatedAt) {
          const elapsed = _.round((dayjs().valueOf() - updatedAt.toDate().getTime()) / 1000)
          if (elapsed > 30 * 60) { // 30min
            const rankings = await cfs.getAll('rankings')
            if (rankings.length) {
              const data = {
                updatedAt: serverTimestamp(),
                data: JSON.stringify(rankings)
              }
              await cfs.set('caches', data, 'ranking')
            }
          }
        }
      }
    },
    i18n: {
      messages: {
        ja: {
          askAdd: 'スコアをランキングに追加しますか？',
          add: '追加する',
        },
        en: {
          askAdd: 'Add your score to the Ranking?',
          add: 'add',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-snack {
    margin-bottom: 1rem;
    ::v-deep .v-snack__wrapper {
      padding: .5rem;
    }
    ::v-deep .v-snack__content {
      font-size: 1rem;
    }
  }
  .v-btn {
    font-size: 1rem;
    margin-top: -2px;
  }
</style>
