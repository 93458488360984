<template>
  <v-container>

    <ScoreSingle />
    <ScoreMulti />

    <div class="suites">
      <Suite
        v-for="suite in suites"
        :key="suite.name"
        :suite="suite"
      ></Suite>
      <div :style="{width: suiteWidth[$vuetify.breakpoint.name]}"></div>
    </div>

    <p align="center" v-if="platform === 'web'">
      {{ $t('desc1') }}<br>{{ $t('desc2') }}<br><br>
      <span>Windows / macOS / Chrome OS / Android / iOS</span>
    </p>

    <Devices />
  </v-container>
</template>

<script>
  import ScoreSingle from './ScoreSingle.vue'
  import ScoreMulti from './ScoreMulti.vue'
  import Suite from './Suite.vue'
  import Devices from './Devices.vue';

export default {
    data() {
      return {}
    },
    computed: {},
		watch: {},
    mounted() {},
    methods: {},
    components: { ScoreSingle, ScoreMulti, Suite, Devices },
    i18n: {
      messages: {
        ja: {
          desc1: 'Goole Octane 2.0 オンラインベンチマーク',
          desc2: 'マルチコアCPU対応版',
        },
        en: {
          desc1: 'Goole Octane 2.0 Online Benchmark',
          desc2: 'with Multi Core CPU Support',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .suites {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  p {
    margin-bottom: 2rem;
    color: #CCCCCC;
  }
</style>
