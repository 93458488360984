
const VER = 1.0;

export default Object.freeze({
	APP_ID: 'octane',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: '',
		isAdmin: false,
		data: {
      docId: null,
      weiQuery: null, // WEI Viewer query
      theme: 'dark', // 'system', 'dark', 'light'
    },
	},
  STORE_STATE: {
    suites: [],
    device: {},
    progressSingle: null,
    progressMulti: null,
    result: {scoreSingle: null, scoreMulti: null, timeSingle: null, timeMulti: null, loop: null},
    rankings: [],
  },
	URLS: {
    pwa: 'https://octane.webmarks.info',
		play: '',
		app: '',
    privacy: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/557',
    request: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/907',
	},
	API_KEY: {
		admob: {
			android: 'ca-app-pub-3940256099942544/6300978111', // デモID
			ios: 'ca-app-pub-3940256099942544/2934735716', // デモID
		},
    gcp: {
      web: 'AIzaSyDqNCosD26YTsMiryWEGS2m2DOxLJzPHrA',
      android: 'AIzaSyBZh2t6uTD0zQMs5M3r2k3gZeaohl2kP4w',
      ios: 'AIzaSyD6u03z5g6C3gZr30IoiyWUrOWHqHvLiog',
    }
	},
  FIREBASE: {
    authDomain: "octane-2021.firebaseapp.com",
    projectId: "octane-2021",
    storageBucket: "octane-2021.appspot.com",
    messagingSenderId: "207428386307",
    appId: "1:207428386307:web:57a746ef85983b82b11836",
    measurementId: "G-NKHY3328S0"
  },
  SOURCE: {
    name: {
      ja: 'Google Octane 2.0',
      en: 'Google Octane 2.0',
    },
    url: {
      ja: 'https://chromium.github.io/octane/',
      en: 'https://chromium.github.io/octane/',
    },
  },
  // 表示メニュー一覧
  MENU: {
    pcOpen: false, // pc時は常に表示
    order: ['home', 'request', 'contact', 'review'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#eb9742',
      primaryBG: '#E26128',
      secondary: '#eb9742',
      secondaryBG: '#eb9742',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#263238', // +2
    },
    dark: {
      primary: '#eb9742', // -1
      primaryBG: '#E26128', // +4
      secondary: '#eb9742', // -1
      secondaryBG: '#eb9742', // +4
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#263238',
    }
  },
  CHART_OPTIONS: {
    bars: 'horizontal',
    fontSize: '16',
    colors: ['#666666'],
    backgroundColor: 'transparent',
    height: '300',
    chartArea: {left: '1%', width: '98%', top: '0', height: '80%'},
    legend: {position: 'none'},
    hAxis: {minValue: 0, textStyle: {color: '#dddddd'}},
    vAxis: {textPosition: 'in', textStyle: {color: '#dddddd'}},
    annotations: {highContrast: false, textStyle: {color: '#ffffff', opacity: 1}},
  },
})
