<template>
  <v-card
    class="score-card"
    @click="run"
    :disabled="progressSingle ? true : false"
  >
    <h1>
      <span v-if="progressSingle === null" :style="styleBefore">Start Octane 2.0 plus</span>
      <span v-else-if="progressSingle === 100" :style="styleAfter">Octane Score:</span>
      <span v-else :style="styleBefore">Running Octane...</span>
      <span v-if="result.scoreSingle" class="score">&nbsp;{{ result.scoreSingle ? result.scoreSingle.toLocaleString() : null }}</span>
    </h1>
    <v-progress-linear
      v-show="progressSingle !== null && progressSingle < 100"
      color="#5e3534"
      height="14"
      :value="progressSingle"
      striped
    ></v-progress-linear>
    <!-- <v-card-actions v-show="time">{{ time }}</v-card-actions> -->
  </v-card>
</template>

<script>
  import Worker from 'worker-loader!../modules/workerSingle'
  export default {
    data() {
      return {
        skip: false, // test mode
        worker: null,
      }
    },
    computed: {},
		watch: {},
    mounted() {
      if (! this.worker) {
        this.worker = new Worker()
      }
    },
    methods: {
      run() {
        const suites = _.cloneDeep(this.suites)
        if (this.skip) {
          // test mode
          this.progressSingle = 100
          this.result = _.assign(_.cloneDeep(this.result), {
            scoreSingle: 38000,
            timeSingle: 30000
          })
          this.suites = _.map(suites, suite => { return _.assign(suite, {score: 50000}) })
        } else {
          // product mode
          let count = 0
          this.progressSingle = 0
          const start = dayjs().valueOf()
          this.worker.addEventListener('message', message => {
            const result = message.data
            if (result.name === 'single') {
              this.result = _.assign(_.cloneDeep(this.result), {
                scoreSingle: _.toNumber(result.score),
                timeSingle: dayjs().valueOf() - start
              })
              this.worker.terminate()
              this.worker = null
            } else {
              count++
              this.progressSingle = (count / 17) * 100
              const index = _.findIndex(suites, suite => { return suite.name === result.name})
              suites[index].score = _.toNumber(result.score)
              this.suites = _.cloneDeep(suites)
            }
          })
          this.worker.postMessage(null)
        }
      }
    },
    components: {},
  }
</script>

<style lang="scss">
  .score-card {
    margin-bottom: 1rem;
    padding: 30px;
    background-color: #eb9742 !important;
    &:first-child {
      margin-top: 1rem;
    }
    h1 {
      opacity: 1 !important;
      line-height: 2.5rem;
      text-align: center;
      color: #5e3534;
      .score {
        display: inline-block;
        font-size: 3.4rem;
        padding-top: .6rem;
      }
    }
    .v-progress-linear {
      margin-top: 1rem;
    }
    .v-card__text {
      font-size: 1.2rem;
    }
  }
</style>

<style lang="scss" scoped>
</style>
