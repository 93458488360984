<template>
  <v-card
    class="score-card"
    v-show="progressMulti !== null"
  >
    <h1>
      <span v-if="progressMulti === 100" :style="styleAfter">Multi Core Score:</span>
      <span v-else-if="progressMulti !== null" :style="styleBefore">Running on multi core ...</span>
      <span v-if="result.scoreMulti" class="score">&nbsp;{{ result.scoreMulti ? result.scoreMulti.toLocaleString() : null }}</span>
    </h1>
    <v-progress-linear
      v-show="progressMulti !== null && progressMulti < 100"
      color="#5e3534"
      height="14"
      :value="progressMulti"
      striped
    ></v-progress-linear>
    <v-card-text v-if="scoreMultis.length > 1">
      <span
        v-for="(score, index) in scoreMultis"
        :key="index"
      ><span v-if="index > 0">&nbsp;/&nbsp;</span>{{ score ? score.toLocaleString() : null }}</span>
    </v-card-text>
    <!-- <v-card-actions>{{ time }} {{ workerNum }}</v-card-actions> -->
  </v-card>
</template>

<script>
  import Worker from 'worker-loader!../modules/workerMulti'
  export default {
    data() {
      return {
        count: 0, // done suite count
        suitesNum: 15, // total suites number
        start: null, // start timestamp
        tasks: [],
        coeff: 1.22,
        // 繰り返し
        times: 0,
        scoreMultis: [],
      }
    },
    computed: {
      // ループ回数
      loop() {
        if (this.userAgent === 'ios') { // iOS
          return 2
        } else { // Windows, Mac, Android
          const byThreads = _.ceil(this.device.cpuThreads * 2 / 15)
          const byScore =_.round(this.result.scoreSingle / 10000)
          return _.min([8, _.max([2, byThreads, byScore])])
        }
      },
      // worker数
      workerNum() {
        if (this.device.cpuThreads) {
          if (this.userAgent === 'android') { // Android
            return this.device.cpuThreads * 2
          } else { // Windows
            return _.max([16, this.device.cpuThreads * 2])
          }
        } else { // iOS, MacOS（iPhone落ちる）
          return this.result.scoreSingle > 20000 ? 12 : 6
        }
      }
    },
		watch: {
      // run
      result() {
        if (this.result.scoreSingle && this.scoreMultis.length < 1) {
          this.run()
        }
      },
    },
    mounted() {},
    methods: {
      async run() {
        this.initialize()
        await this.sleep(5000)
        this.tasks = _.flatten(_.fill(Array(this.loop), _.range(15)))
        // console.log(this.scoreSingle, this.times, this.workerNum)
        // console.log(this.tasks)
        this.start = dayjs().valueOf()
        for (let i = 0; i < this.workerNum; i++) {
          if (i < this.tasks.length) {
            this.task(i % this.suitesNum)
            this.tasks = _.drop(this.tasks)
          }
        }
      },
      task(suiteIndex) {
        let worker = new Worker()
        worker.addEventListener('message', async message => {
          const result = message.data
          if (result.name === 'single') {
            this.count++
            this.progressMulti = (this.count / (this.suitesNum * this.loop)) * 100
            worker.terminate()
            worker = null
            if (this.count === this.suitesNum * this.loop) {
              // done
              const time = dayjs().valueOf() - this.start
              const scoreMulti = _.round((1 / time) * this.coeff * 1000 * 1000 * 1000 * this.loop)
              this.scoreMultis.push(scoreMulti)
              this.result = _.assign(_.cloneDeep(this.result), {
                scoreMulti: scoreMulti,
                timeMulti: time,
                loop: this.loop,
              })
            } else if (this.tasks.length > 0) {
              // next
              this.task(this.tasks[0])
              this.tasks = _.drop(this.tasks)
            }
          } else {
            const suites = _.cloneDeep(this.suites)
            const index = _.findIndex(suites, suite => { return suite.name === result.name})
            suites[index].scores.push(_.toNumber(result.score))
            suites[index].progress = (suites[index].scores.length / this.loop) * 100
            this.suites = _.cloneDeep(suites)
          }
        })
        worker.postMessage(suiteIndex)
      },
      initialize() {
        this.progressMulti = 0
        this.count = 0
        this.suites = _.map(this.suites, suite => {
          return _.assign(_.cloneDeep(suite), {progress: 0, scores: []})
        })
      },
    },
    components: {},
  }
</script>

<style lang="scss" scoped>
</style>
