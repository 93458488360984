import INIT from '../init'
import Vue from 'vue'
import _store from '../commons/modules/_store'
import _mixin from '../commons/modules/_mixin'
// import _mixinLeaflet from '../commons/modules/_mixinLeaflet'
// import _mixinAdmob from '../commons/modules/_mixinAdmob'
import { serverTimestamp, where } from "firebase/firestore"

const data = {
  // each suite box width
  suiteWidth: {xs: '100%', sm: '49%', md: '32%', lg: '24%', xl: '24%'},
}
const computed = {
  // score text style before
  styleBefore() {
    const fontSize =  {xs: '2.1rem', sm: '2.7rem', md: '3rem', lg: '3rem', xl: '3rem'}
    return {fontSize: fontSize[this.$vuetify.breakpoint.name]}
  },
  // score text style after
  styleAfter() {
    const fontSize =  {xs: '2.1rem', sm: '2.5rem', md: '3rem', lg: '3rem', xl: '3rem'}
    return {fontSize: fontSize[this.$vuetify.breakpoint.name]}
  },
  where() {
    if (this.device.name || this.device.cpu) {
      return this.device.cpu ? where('cpu', '==', this.device.cpu) : where('name', '==', this.device.name)
    } else {
      return null
    }
  },
}
const methods = {
  // 平均値算出
  getMedianResult(results) {
    const cpu = results[0].cpu ? this.getShortCpuName(results[0].cpu) : null
    const result = _.assign({}, results[0], {
      id: cpu ? cpu.replaceAll(' ', '_') : results[0].name.replaceAll(' ', '_'),
      cpu: cpu,
      scoreMulti: this.getMedian(_.map(results, 'scoreMulti')),
      scoreSingle: this.getMedian(_.map(results, 'scoreSingle')),
      scoreMultis: _.map(results, 'scoreMulti'),
      scoreSingles: _.map(results, 'scoreSingle'),
      memory: this.getMedian(_.map(results, 'memory')),
      createdAt: serverTimestamp(), // 常に更新されるバグ
      updatedAt: serverTimestamp(),
    })
    _.forEach(['timeSingle', 'timeMulti', 'platform', 'osVersion', 'loop'], key => {
      delete result[key]
    })
    return result
  },
  // Median
  getMedian(arr) {
    if (arr.length) {
      arr = _.sortBy(arr)
      return arr.length > 1 ? arr[_.floor(arr.length / 2)] : arr[0]
    } else {
      return null
    }
  },
  // cpu name
  getShortCpuName(cpu) {
    return cpu.split('@')[0]
    .replace('Intel(R) Core(TM)', '')
    .replace('Intel(R) Celeron(R)', '')
    .replace('with Radeon Vega Graphics', '')
    .replace('APU with AMD Radeon R2 Graphics', '')
    .replace('with Radeon Graphics', '')
    .replace('16-Core Processor', '')
    .replace('12-Core Processor', '')
    .replace('8-Core Processor', '')
    .replace('6-Core Processor', '')
    .replace('4-Core Processor', '')
    .replace('11th Gen', '')
    .replace('CPU', '')
    .replace('AMD', '')
    .replace('(R)', '')
    .replace(/  +/g, ' ')
    .trim()
  },
  // sleep
  sleep(time) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  },
}

// computed for store
const computed4store = (() => {
  const computed4store = {}
  const state = Object.assign({}, _store.state, INIT.STORE_STATE)
  _.forEach(state, (init, key) => {
    computed4store[key] = {
      get() { return this.$store.state[key] },
      set(value) { this.$store.commit('set' + _.capitalize(key), value) }
    }
  })
  return computed4store
})()
// computed for appData
const computed4appData = (() => {
  const computed4appData = {}
  _.forEach(INIT.LOCAL_DATA_STRUCTURE.data, (init, key) => {
    computed4appData[key] = {
      get() { return _.cloneDeep(_.get(this.storage.data, key)) },
      set(value) {
        const appData = _.cloneDeep(this.storage.data)
        appData[key] = value
        this.storage = Object.assign({}, this.storage, {data: appData})
      }
    }
  })
  return computed4appData
})()

Vue.mixin({
  data: () => (Object.assign(_mixin.data, data)),
  computed: Object.assign(_mixin.computed, computed4store, computed4appData, computed),
  methods: Object.assign(_mixin.methods, methods),
  // methods: Object.assign(_mixin.methods, _mixinLeaflet.methods, _mixinAdmob.methods, methods),
})
