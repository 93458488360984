<template>
  <fragment>
    <v-btn
      bottom right
      dark
      fab
      fixed
      large
      color="secondaryBG"
      v-show="result.scoreMulti"
      @click="capture"
    >
      <v-icon v-if="platform === 'web'">mdi-download</v-icon>
      <!-- <v-icon v-else>mdi-share-variant</v-icon> -->
    </v-btn>

    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-if="platform === 'web'">Download captured image?</v-card-text>
        <v-card-text v-else>Share your score?</v-card-text>
        <v-card-text><div id="captured"></div></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialog = false"
          >{{ $t('dialog.no') }}</v-btn>
          <v-btn
            text
            @click="share"
            color="primary"
          >{{ $t('dialog.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </fragment>
</template>

<script>
  import html2canvas from 'html2canvas'
  import { Share } from '@capacitor/share'
  import { Filesystem, Directory } from '@capacitor/filesystem'

  export default {
    data() {
      return {
        dialog: false,
        canvas: null,
      }
    },
    computed: {},
    async mounted() {},
    methods: {
      capture() {
        setTimeout(() => {
          html2canvas(document.querySelector('#' + this.tabId), {
          // html2canvas(document.querySelector('#' + this.tabId + ' .container'), {
            backgroundColor: '#1E1E1E'
          }).then((canvas) => {
            this.canvas = canvas
            this.dialog = true
            setTimeout(() => {
              document.getElementById('captured').innerHTML = canvas
              const captured = document.getElementById('captured')
              if (captured.childNodes.length) {
                captured.removeChild(captured.childNodes[0])
              }
              document.getElementById('captured').appendChild(canvas)
            }, 0)
          })
        }, 0)
      },
      async share() {
        const fileName = 'octane2.0_plus.png'
        if (this.platform === 'web') {
          // WEB ダウンロード
          let downloadEle = document.createElement('a')
          downloadEle.href = this.canvas.toDataURL('image/png')
          downloadEle.download = fileName
          downloadEle.click()
        } else {
          // Native ファイル保存
          const res = await Filesystem.writeFile({
            path: fileName,
            data: this.canvas.toDataURL('image/png'),
            directory: Directory.Cache,
          })
          Share.share({
            title: 'Octane 2.0 plus',
            url: 'file:/' + res.uri,
            dialogTitle: 'Share result',
          })
        }
        this.dialog = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog__content {
    ::v-deep .v-dialog {
      width: auto;
      max-width: 90% !important;
    }
  }
  #captured {
    text-align: center;
    ::v-deep canvas {
      height: 300px !important;
      width: auto !important;
    }
  }
</style>
